<template>
  <b-container>
    <b-row style="height: 100vh" align-content="center" align-h="center" align-v="center">
      <b-col cols="5">
        <b-card id="form" class="p-3">
          <h1>PLFE</h1>
          <h2 class="mb-5">Ingreso</h2>

          <b-form @submit.stop.prevent="doLogin" >
            <b-form-input
                :disabled="formLoading"
                :state="formValid"
                v-model="usuarioId"
                type="text"
                class="mb-3"
                placeholder="Identificador usuario"
            ></b-form-input>

            <b-form-input
                :disabled="formLoading"
                :state="formValid"
                v-model="usuarioSecreto"
                type="password"
                class="mb-5"
                placeholder="Contraseña usuario"
            ></b-form-input>

            <b-button :disabled="formLoading" v-on:click="doLogin" variant="primary">Ingresar al panel</b-button>
          </b-form>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const axios = require('axios');

export default {
name: "LoginScreen",
 data() {
  return {
    usuarioId: "",
    usuarioSecreto: "",
    formLoading: false,
    formValid: null,
  }
 },
  methods: {
    async doLogin() {
      this.$data.formLoading = true;
      this.$data.formValid = null;
      var formData = new FormData();

      formData.append('usuario_id', this.$data.usuarioId)
      formData.append('usuario_secreto', this.$data.usuarioSecreto)

      var response = await axios({
        method: 'post',
        url: '/api/login/',
        data: formData
      });

      if (response.data) {
        this.$data.formValid = true;
        this.$emit('update-auth', true);
      }
      else {
        this.$data.formValid = false;
      }
      this.$data.formLoading = false;
    }
  }
}
</script>

<style scoped>

#form {

}

</style>