"use strict";
module.exports.rutClean = function(value) {
  return typeof value === "string"
    ? value.replace(/[^0-9kK]+/g, "").toUpperCase()
    : "";
};

module.exports.rutValidate = function(value) {
  if (typeof value !== "string") {
    return false;
  }
  var rut = module.exports.rutClean(value);
  var rutDigits = parseInt(rut.slice(0, -1), 10);
  var m = 0;
  var s = 1;
  while (rutDigits > 0) {
    s = (s + (rutDigits % 10) * (9 - (m++ % 6))) % 11;
    rutDigits = Math.floor(rutDigits / 10);
  }
  var checkDigit = s > 0 ? String(s - 1) : "K";
  return checkDigit === rut.slice(-1);
};

module.exports.rutFormat = function(value) {
  var rut = module.exports.rutClean(value);
  if (rut.length <= 1) {
    return rut;
  }
  var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
  for (var i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + "." + result;
  }
  return result;
};
