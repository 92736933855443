import Vue from 'vue'
import App from './App.vue'
import UTIL from "@/app/util.js";

// BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// NOTIFICACIONES
import Notifications from "vue-notification";
Vue.use(Notifications);

// VALIDACION
import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend
} from "vee-validate";

import {
  required,
  max,
} from "vee-validate/dist/rules";

configure({
  classes: {
    invalid: "is-invalid"
  }
});

extend("max", {
  ...max,
  message: "Este campo no puede exceder los {length} carácteres"
});

extend("required", {
  ...required,
  message: "Este campo es requerido"
});

extend("rut", {
  validate: value => {
    return UTIL.rutValidate(value);
  },
  message: "El RUT ingresado es inválido"
});

extend("is_number", {
  validate: value => !isNaN(value),
  message: "Este campo sólo debe contener números"
});

Vue.config.productionTip = false

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

new Vue({
  render: h => h(App),
}).$mount('#app')
