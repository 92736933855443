<template>
  <b-container fluid>
    <b-row style="height: 100vh" >
      <b-col cols="12">
        <b-navbar class="mt-3 mb-3" toggleable="lg" type="dark" variant="primary">
          <b-navbar-brand href="#">FE Licencias v1.0</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mt-3 mt-sm-0">
              <b-button class="ml-5" :variant="(tab==='licences') ? 'light' : 'outline-light'" @click="tab = 'licences'">Licencias</b-button>
              <b-button class="ml-2" :variant="(tab==='school') ? 'light' : 'outline-light'" @click="tab = 'school'">Establecimientos</b-button>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-button variant="danger" @click="exitLogin">Salir</b-button>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
      <b-col cols="12">
        <b-card id="panel" border-variant="primary" bg-variant="light">


          <LicensePanel v-if="tab==='licences'"></LicensePanel>
          <SchoolPanel v-if="tab==='school'"></SchoolPanel>

        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const axios = require('axios');
import LicensePanel from './LicensePanel.vue'
import SchoolPanel from './SchoolPanel.vue'

export default {
  components: {
    LicensePanel,
    SchoolPanel
  },
 data() {
  return {
    tab: "licences"

  }
 },
  methods: {
      async exitLogin() {
        await axios({
          method: 'post',
          url: '/api/logout/',
        });
        this.$emit('update-auth', false);
      }
  }
}
</script>

<style scoped>

#panel {
  min-height: 80vh;
  margin-bottom: 30px;
}

</style>