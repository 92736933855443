<template>
  <div>
    <b-button-toolbar>
      <b-button @click="openAddModal" :disabled="tableBusy" variant="primary">Nueva licencia</b-button>

      <b-input-group class="ml-3">
        <b-input-group-append>
          <b-button variant="primary">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group-append>
        <b-form-input type="search" v-model="filterText" placeholder="Buscar en esta tabla"
                      :disabled="tableBusy"></b-form-input>

      </b-input-group>

      <b-button @click="refreshData" :disabled="tableBusy" class="ml-3" variant="outline-success">Actualizar</b-button>
    </b-button-toolbar>
    <hr/>

    <b-table @filtered="onFiltered" :filter="filterText" :per-page="perPage" :current-page="currentPage"
             :busy="tableBusy" head-variant="dark" borderless
             striped hover :items="tableData" :fields="tableFields" :sort-by="tableSort.sortBy"
             :sort-desc="tableSort.sortDesc">
      <template #table-caption>


        <b-container fluid v-if="!tableBusy">

          <b-row v-if="totalRows !== 0">
            <b-col> Se muestran {{ totalRows }} registros
            </b-col>
            <b-col>
              <b-pagination
                  :disabled=tableBusy
                  hide-ellipsis
                  pills
                  align="right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row align-h="center" v-else>

            <h4 class="m-5 d-block text-muted">
              <b-icon icon="dash-circle" class="mr-3 text-danger"></b-icon>
              No se encontraron registros
            </h4>

          </b-row>
        </b-container>


      </template>
      <template #table-busy>
        <div class="text-center m-5">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-button variant="info" size="sm" @click="openEditModal(row.item)" class="mr-1">
          Editar
        </b-button>
        <b-button variant="danger" size="sm" @click="deleteLicense(row.item.licencia_id)" class="mr-1">
          Borrar
        </b-button>
      </template>
    </b-table>

    <b-modal header-bg-variant="primary" header-text-variant="light" footer-bg-variant="light" id="license-modal"
             size="lg" :title="(modalEditing) ? 'Editar licencia' : 'Nueva licencia'">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form id="form" @submit.prevent="handleSubmit((modalEditing) ? editLicense : createLicense)">

          <ValidationProvider
              rules="required"
              v-slot="{ classes, errors }"
          >
          <div class="form-group">
            <label>Identificador establecimiento (RBD)</label>
            <model-list-select
                :is-disabled="modalBusy"
                :list="schoolData"
                v-model="licencia.establecimiento_id"
                option-value="establecimiento_id"
                option-text="establecimiento_nombre"
                :customText="schoolFormat"
                class="form-control"
                placeholder="Buscar establecimiento"
                :is-error="(errors[0] != null)"

            >
            </model-list-select>
            <small class="form-text text-muted">
              {{ errors[0] }}
            </small>
          </div>
          </ValidationProvider>



          <ValidationProvider
              rules="required|max:12|rut"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Identificador licencia (RUT)</label>
              <b-form-input
                  :disabled="modalBusy || modalEditing"
                  v-model="licencia_id"
                  class="form-control"
                  :class="classes"
              ></b-form-input>
              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

          <ValidationProvider
              rules="required|max:50"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Nombre persona</label>
              <b-form-input
                  :disabled="modalBusy"
                  v-model="licencia.licencia_nombre"
                  class="form-control"
                  :class="classes"
              ></b-form-input>
              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

          <ValidationProvider
              rules="required|max:50"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Apellido persona</label>
              <b-form-input
                  :disabled="modalBusy"
                  v-model="licencia.licencia_apellido"
                  class="form-control"
                  :class="classes"
              ></b-form-input>
              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

          <ValidationProvider
              rules="required|is_number"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Instalaciones disponibles</label>

              <b-form-spinbutton :disabled="modalBusy" v-model="licencia.licencia_disponible" class="form-control"
                                 :class="classes" min="0" max="100"></b-form-spinbutton>

              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

        </form>

      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button :disabled="modalBusy" variant="danger" @click="cancel()">
          Cancelar
        </b-button>
        <b-button :disabled="modalBusy" form="form" type="submit" variant="success">
          Guardar
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
const axios = require('axios');

import UTIL from "@/app/util.js";
import {ModelListSelect} from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  components: {
    ModelListSelect
  },
  data() {
    return {
      tableBusy: true,
      filterText: "",
      tableData: [],
      tableFields: [
        {
          key: 'establecimiento_id',
          label: 'Establecimiento',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.establecimiento_nombre} (${item.establecimiento_id})`;
          }
        },
        {
          key: 'licencia_id',
          label: 'Identificador',
          sortable: true,
          sortByFormatted: true,
          class: ["text-center"],
          formatter: (value, key, item) => {
            return `${UTIL.rutFormat(item.licencia_id)}`;
          }
        },
        {
          key: 'licencia_nombres',
          label: 'Nombre',
          sortable: true,
          class: ["text-left"],
          formatter: (value, key, item) => {
            return `${item.licencia_nombre} ${item.licencia_apellido}`;

          }
        },
        {
          key: 'licencia_estado',
          label: 'Estado Licencia',
          sortable: true,
          sortByFormatted: true,
          class: ["text-center"],
          formatter: (value, key, item) => {
            return `${item.licencia_instancia} / ${item.licencia_disponible}`;
          }
        },
        {key: 'actions', label: 'Acciones'}
      ],
      tableSort: {
        "sortBy": "establecimiento_id",
        "sortDesc": false
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      modalBusy: false,
      modalEditing: false,
      licencia: {
        establecimiento_id: null,
        licencia_id: "",
        licencia_nombre: "",
        licencia_apellido: "",
        licencia_disponible: 1,
      },
      schoolData: [],
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openAddModal: function () {
      this.$data.licencia.licencia_id = "";
      this.$data.licencia.licencia_nombre = "";
      this.$data.licencia.licencia_apellido = "";
      this.$data.licencia.licencia_disponible = 1;
      this.$data.modalEditing = false;
      this.$bvModal.show('license-modal');
    },
    openEditModal: function (licencia) {
      this.$data.licencia.establecimiento_id = licencia.establecimiento_id;
      this.$data.licencia.licencia_id = licencia.licencia_id;
      this.$data.licencia.licencia_nombre = licencia.licencia_nombre;
      this.$data.licencia.licencia_apellido = licencia.licencia_apellido;
      this.$data.licencia.licencia_disponible = licencia.licencia_disponible;
      this.$data.modalEditing = true;
      this.$bvModal.show('license-modal');
    },
    createLicense: async function () {
      this.$data.modalBusy = true;
      var formData = new FormData();

      formData.append('establecimiento_id', this.$data.licencia.establecimiento_id)
      formData.append('licencia_id', this.$data.licencia.licencia_id)
      formData.append('licencia_nombre', this.$data.licencia.licencia_nombre)
      formData.append('licencia_apellido', this.$data.licencia.licencia_apellido)
      formData.append('licencia_disponible', this.$data.licencia.licencia_disponible)

      var response = await axios({
        method: 'post',
        url: '/api/license/add',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "La licencia fue añadida correctamente"
        });
        this.$bvModal.hide('license-modal');

        this.refreshData();

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al ingresar el registro. Por favor verifique que no exista."
        });
      }

      this.$data.modalBusy = false;

    },
    editLicense: async function () {

      this.$data.modalBusy = true;
      var formData = new FormData();

      formData.append('establecimiento_id', this.$data.licencia.establecimiento_id)
      formData.append('licencia_id', this.$data.licencia.licencia_id)
      formData.append('licencia_nombre', this.$data.licencia.licencia_nombre)
      formData.append('licencia_apellido', this.$data.licencia.licencia_apellido)
      formData.append('licencia_disponible', this.$data.licencia.licencia_disponible)

      var response = await axios({
        method: 'post',
        url: '/api/license/edit',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "La licencia fue actualizada correctamente"
        });
        this.$bvModal.hide('license-modal');

        this.refreshData();

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al actualizar el registro."
        });
      }

      this.$data.modalBusy = false;

    },
    deleteLicense: async function (licencia_id) {

      if (!confirm("Está seguro (?)")) {
        return;
      }

      this.$data.tableBusy = true;

      var formData = new FormData();
      formData.append('licencia_id', licencia_id)

      var response = await axios({
        method: 'post',
        url: '/api/license/delete',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "La licencia fue eliminada correctamente"
        });

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al eliminar el registro."
        });
      }

      this.refreshData();

    },
    async refreshData() {
      this.$data.tableBusy = true;

      var response = await axios({
        method: 'post',
        url: '/api/license/all'
      });

      this.$data.tableData = response.data;
      this.$data.tableBusy = false;
    },
    schoolFormat: function (school) {
      return `${school.establecimiento_nombre} (${school.establecimiento_id})`;
    }
  },
  async created() {
    var response_license = await axios({
      method: 'post',
      url: '/api/license/all'
    });

    var response_school = await axios({
      method: 'post',
      url: '/api/school/all'
    });

    this.$data.schoolData = response_school.data;

    this.$data.tableData = response_license.data;
    this.$data.tableBusy = false;
  },
  watch: {
    tableData: function (newValue) {
      this.totalRows = newValue.length
    }
  },
  computed: {
    licencia_id: {
      get: function () {
        return UTIL.rutFormat(this.$data.licencia.licencia_id);
      },
      set: function (value) {
        this.$data.licencia.licencia_id = UTIL.rutClean(value);
      }
    },
  }
}
</script>

<style scoped>

</style>