<template>
  <div id="app">
    <LoginScreen v-if="!authStatus" @update-auth="updateAuth"></LoginScreen>
    <PanelScreen v-else @update-auth="updateAuth"></PanelScreen>
    <notifications width="350" position="top right" class="mt-1" v-bind:duration="5000" />
  </div>
</template>

<script>
import LoginScreen from './views/LoginScreen.vue'
import PanelScreen from './views/PanelScreen.vue'

export default {
  name: 'App',
  components: {
    LoginScreen,
    PanelScreen
  },
  data() {
    return {
      authStatus: false
    }
  },
  methods: {
    updateAuth(state) {
      this.$data.authStatus = state;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #2c3e50;
  min-height: 100vh;
  padding-bottom: 30px;
}

/* NOTICATION CSS */
.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 14px !important;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}
.vue-notification.warn {
  background: #dc8400 !important;
  border-left-color: #f48a06;
}

.vue-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.vue-notification.success {
  background: #04b201 !important;
  border-left-color: #42a85f;
}
</style>
