<template>
  <div>
    <b-button-toolbar>
      <b-button @click="openAddModal" :disabled="tableBusy" variant="primary">Nuevo establecimiento</b-button>


      <b-input-group class="ml-3">
        <b-input-group-append>
          <b-button variant="primary">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group-append>
        <b-form-input type="search" v-model="filterText" placeholder="Buscar en esta tabla"
                      :disabled="tableBusy"></b-form-input>

      </b-input-group>

      <b-button @click="refreshData" :disabled="tableBusy" class="ml-3" variant="outline-success">Actualizar</b-button>
    </b-button-toolbar>
    <hr/>

    <b-table @filtered="onFiltered" :filter="filterText" :per-page="perPage" :current-page="currentPage"
             :busy="tableBusy" head-variant="dark" borderless
             striped hover :items="tableData" :fields="tableFields" :sort-by="tableSort.sortBy"
             :sort-desc="tableSort.sortDesc">
      <template #table-caption>


        <b-container fluid v-if="!tableBusy">

          <b-row v-if="totalRows !== 0">
            <b-col> Se muestran {{ totalRows }} registros
            </b-col>
            <b-col>
              <b-pagination
                  :disabled=tableBusy
                  hide-ellipsis
                  pills
                  align="right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row align-h="center" v-else>

            <h4 class="m-5 d-block text-muted">
              <b-icon icon="dash-circle" class="mr-3 text-danger"></b-icon>
              No se encontraron registros
            </h4>

          </b-row>
        </b-container>


      </template>
      <template #table-busy>
        <div class="text-center m-5">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-button variant="info" size="sm" @click="openEditModal(row.item)" class="mr-1">
          Editar
        </b-button>
        <b-button variant="danger" size="sm" @click="deleteSchool(row.item.establecimiento_id)" class="mr-1">
          Borrar
        </b-button>
      </template>
    </b-table>

    <b-modal header-bg-variant="primary" header-text-variant="light" footer-bg-variant="light" id="school-modal" size="lg" :title="(modalEditing) ? 'Editar establecimiento' : 'Nuevo establecimiento'">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form id="form" @submit.prevent="handleSubmit((modalEditing) ? editSchool : createSchool)">
          <ValidationProvider
              rules="required|max:10|is_number"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Identificador establecimiento (RBD)</label>
              <b-form-input
                  :disabled="modalBusy || modalEditing"
                  v-model="establecimiento.establecimiento_id"
                  class="form-control"
                  :class="classes"
              ></b-form-input>
              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

          <ValidationProvider
              rules="required|max:150"
              v-slot="{ classes, errors }"
          >
            <div class="form-group">
              <label>Nombre establecimiento</label>
              <b-form-input
                  :disabled="modalBusy"
                  v-model="establecimiento.establecimiento_nombre"
                  class="form-control"
                  :class="classes"
              ></b-form-input>
              <small class="form-text text-muted">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>

        </form>


      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button :disabled="modalBusy" variant="danger" @click="cancel()">
          Cancelar
        </b-button>
        <b-button :disabled="modalBusy" form="form" type="submit" variant="success">
          Guardar
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  data() {
    return {
      tableBusy: true,
      filterText: "",
      tableData: [],
      tableFields: [
        {
          key: 'establecimiento_id',
          label: 'RBD',
          sortable: true,
        },
        {
          key: 'establecimiento_nombre',
          label: 'Nombre',
          sortable: true,
          class: ["text-center"],
        },
        {key: 'actions', label: 'Acciones'}
      ],
      tableSort: {
        "sortBy": "establecimiento_id",
        "sortDesc": false
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      modalBusy: false,
      modalEditing: false,
      establecimiento: {
        establecimiento_id: "",
        establecimiento_nombre: "",
      }
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openAddModal: function () {
      this.$data.establecimiento.establecimiento_id = "";
      this.$data.establecimiento.establecimiento_nombre = "";
      this.$data.modalEditing = false;
      this.$bvModal.show('school-modal');
    },
    openEditModal: function (establecimiento) {
      this.$data.establecimiento.establecimiento_id = establecimiento.establecimiento_id;
      this.$data.establecimiento.establecimiento_nombre = establecimiento.establecimiento_nombre;
      this.$data.modalEditing = true;
      this.$bvModal.show('school-modal');
    },
    createSchool: async function () {
      this.$data.modalBusy = true;
      var formData = new FormData();

      formData.append('establecimiento_id', this.$data.establecimiento.establecimiento_id)
      formData.append('establecimiento_nombre', this.$data.establecimiento.establecimiento_nombre)

      var response = await axios({
        method: 'post',
        url: '/api/school/add',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "El establecimiento fue añadido correctamente"
        });
        this.$bvModal.hide('school-modal');

        this.refreshData();

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al ingresar el registro. Por favor verifique que no exista."
        });
      }

      this.$data.modalBusy = false;

    },
    editSchool: async function() {

      this.$data.modalBusy = true;
      var formData = new FormData();

      formData.append('establecimiento_id', this.$data.establecimiento.establecimiento_id)
      formData.append('establecimiento_nombre', this.$data.establecimiento.establecimiento_nombre)

      var response = await axios({
        method: 'post',
        url: '/api/school/edit',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "El establecimiento fue actualizado correctamente"
        });
        this.$bvModal.hide('school-modal');

        this.refreshData();

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al actualizar el registro."
        });
      }

      this.$data.modalBusy = false;

    },
    deleteSchool: async function (establecimiento_id) {

      if (!confirm("Esta acción borrará además todas las licencias asociadas a este establecimiento. Está seguro (?)")) {
        return;
      }

      this.$data.tableBusy = true;

      var formData = new FormData();
      formData.append('establecimiento_id', establecimiento_id)

      var response = await axios({
        method: 'post',
        url: '/api/school/delete',
        data: formData
      });

      if (response.data) {
        this.$notify({
          type: "success",
          title: "Operación exitosa",
          text: "El establecimiento fue eliminado correctamente"
        });

      } else {
        this.$notify({
          type: "warn",
          title: "Error en la operación",
          text:
              "Ocurrió un error al eliminar el registro."
        });
      }

      this.refreshData();

    },
    async refreshData() {
      this.$data.tableBusy = true;

      var response = await axios({
        method: 'post',
        url: '/api/school/all'
      });

      this.$data.tableData = response.data;
      this.$data.tableBusy = false;
    }
  },
  async created() {
    var response = await axios({
      method: 'post',
      url: '/api/school/all'
    });

    this.$data.tableData = response.data;
    this.$data.tableBusy = false;
  },
  watch: {
    tableData: function (newValue) {
      this.totalRows = newValue.length
    }
  }
}
</script>

<style scoped>

</style>